import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './TopBar.css';

function TopBar() {
    const navigate = useNavigate();
    const companyName = sessionStorage.getItem('company');
    const username = sessionStorage.getItem('username');

    const handleLogout = () => {
        sessionStorage.removeItem('username');
        sessionStorage.removeItem('company');
        navigate('/');
    };

    // Function to load logo dynamically
    const getLogoUrl = (username) => {
        try {
            // Try to require the logo dynamically with any image format
            const logo = require(`../logos/${username}`); // Assuming username includes the extension
            // const logo = require(`/build/static/media/${username}`);
            return logo; // Use default export (path) from the required module
        } catch (error) {
            // Handle error if logo file doesn't exist or other error
            console.error(`Logo for ${username} not found.`);
            return ''; // Return empty string or default image path as fallback
        }
    };

    // Get logo URL dynamically
    const logoUrl = getLogoUrl(`${username}.jpg`); // Adjust if username already includes extension

    return (
        <div className="top-bar">
            <div className="top-bar-left">
                {logoUrl && <img src={logoUrl} alt={`Logo of ${companyName}`} className="company-logo" />}
                <h2>{companyName}</h2>
            </div>
            <div className="top-bar-right">
                <nav>
                    <ul className="top-bar-menu">
                        <li><Link to="/dashboard">Dashboard</Link></li>
                        {/*<li><Link to="/monitor">Monitor</Link></li>*/}
                        <li><Link to="/devices">Devices</Link></li>
                        {sessionStorage.getItem('username') === "sign_blast" && (
                            <li><Link to="/settings">Admin</Link></li>
                        )}
                    </ul>
                </nav>
                <button className="btn-logout" onClick={handleLogout}>
                    Logout
                </button>
            </div>
        </div>
    );
}

export default TopBar;
