import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar';
import axios from 'axios';
import './Devices.css';

function Device() {
  const navigate = useNavigate();

  const [devices, setDevices] = useState([]);
  const [editedDevices, setEditedDevices] = useState([]);

  useEffect(() => {
    fetchDevicesData();
  }, []);

  // Logged In User
  const username = sessionStorage.getItem('username');

  // Fetch devices data
  const fetchDevicesData = async () => {
    if (username) {
      try {
        const response = await axios.get(`https://lespeedsigns-server.australiaeast.cloudapp.azure.com:3001/api/devices?username=${username}`);
        setDevices(response.data.devices);
        // Initialize editedDevices with the fetched devices
        setEditedDevices(response.data.devices.map(device => ({ ...device })));
      } catch (error) {
        console.error('Error fetching devices data:', error);
      }
    } else {
      navigate('/');
    }
  };

  // Handle cell value change
  const handleCellChange = (value, rowIndex, columnKey) => {
    const updatedDevices = [...editedDevices];
    updatedDevices[rowIndex][columnKey] = value;
    setEditedDevices(updatedDevices);
  };

  // Update devices data on server
  const handleUpdateData = async () => {
    try {
      const response = await axios.post(`https://lespeedsigns-server.australiaeast.cloudapp.azure.com:3001/api/update_device`, {
      //const response = await axios.post(`http://localhost:3001/api/update_device`, {
        username,
        devices: editedDevices,
      });
      // Optionally handle success response
      console.log('Devices updated successfully:', response.data);
      // Update devices state with edited devices
      setDevices(editedDevices);
      if (!alert("Device(s) Updated Successfully!")){window.location.reload();}
    } catch (error) {
      console.error('Error updating devices data:', error);
    }
  };

  return (
    <div className="monitor">
      <TopBar />
      <div className="main-content">
        <div className="content-body">
          <div className="table-container">
            <table className="devices-table">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Label</th>
                  <th>Description</th>
                  <th>Address</th>
                  <th>Latitude</th>
                  <th>Longitude</th>
                </tr>
              </thead>
              <tbody>
                {editedDevices.map((device, rowIndex) => (
                  <tr key={device.id}>
                    <td>{device.id}</td>
                    <td width={50}>{device.label}</td>
                    <td><input type="text" style={{width: "350px"}} value={device.note} onChange={(e) => handleCellChange(e.target.value, rowIndex, 'note')} /></td>
                    <td><input type="text" style={{width: "350px"}} value={device.addr} onChange={(e) => handleCellChange(e.target.value, rowIndex, 'addr')} /></td>
                    <td><input type="text" value={device.lat} onChange={(e) => handleCellChange(e.target.value, rowIndex, 'lat')} /></td>
                    <td><input type="text" value={device.lon} onChange={(e) => handleCellChange(e.target.value, rowIndex, 'lon')} /></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <button onClick={handleUpdateData}>Update</button>
        </div>
      </div>
    </div>
  );
}

export default Device;
