import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Login';
import Dashboard from './Pages/Dashboard'; 
import Monitor from './Pages/Monitor'; 
import Devices from './Pages/Devices';
import Settings from './Pages/Settings';
import Quote from './Pages/Quote';
import Contact from './Pages/Contact';
import Products from './Pages/Products';
import Manufacturing from './Pages/Manufacturing';
import AusMade from './Pages/AusMade';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes> 
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/quote" element={<Quote />} />
          {/* 
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/monitor" element={<Home />} />
          <Route path="/devices" element={<Devices />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/quote" element={<Quote />} />
          <Route path="/contact" element={<Home />} />
          <Route path="/products" element={<Home />} />
          <Route path="/manufacturing" element={<Home />} />
          <Route path="/ausmade" element={<Home />} />
           */}
        </Routes>
      </div>
    </Router>
  );
}

export default App;
