import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './NavBar.css';
import logo from '../logos/LED-Speed-Signs-Logo.png';
import { FaBars } from 'react-icons/fa';

function NavBar() {
    const navigate = useNavigate();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    const toggleDropdown = () => {
        setDropdownOpen(!dropdownOpen);
    };

    const handleNavigation = (path) => {
        navigate(path);
        setDropdownOpen(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className={`navbar ${scrolled ? 'navbar-scrolled' : ''}`}>
            <Link to="/" className="navbar-logo-link">
                <img src={logo} alt="LED Speed Signs Logo" className="navbar-logo" />
            </Link>
            <div className="navbar-links">
                {!scrolled && (
                    <>
                        <span className="navbar-link" onClick={() => handleNavigation('/')}>
                            HOME
                        </span>
                        <span className="navbar-link" onClick={() => handleNavigation('/quote')}>
                            GET A QUOTE
                        </span>
                        <span className="navbar-link" onClick={() => handleNavigation('/contact')}>
                            CONTACT US
                        </span>
                        <span className="navbar-link" onClick={() => handleNavigation('/products')}>
                            PRODUCTS
                        </span>
                        <span className="navbar-link" onClick={() => handleNavigation('/manufacturing')}>
                            MANUFACTURING
                        </span>
                        <span className="navbar-link" onClick={() => handleNavigation('/ausmade')}>
                            AUSTRALIAN MADE
                        </span>
                        <span className="navbar-link login-link" onClick={() => handleNavigation('/login')}>
                            LOGIN
                        </span>
                    </>
                )}
                <a href="tel:+61-000-000-000" className={`navbar-link phone-number ${scrolled ? 'scrolled-text-phone-num' : ''}`}>
                    (07) 0000 0000
                </a>
                {scrolled && (
                    <div className="navbar-dropdown">
                        <button className={`navbar-dropdown-button ${scrolled ? 'scrolled-text' : ''}`} onClick={toggleDropdown}>
                            <FaBars />
                        </button>
                        {dropdownOpen && (
                            <div className="navbar-dropdown-content">
                                <span className={`navbar-link ${scrolled ? 'scrolled-text' : ''}`} onClick={() => handleNavigation('/')}>
                                    HOME
                                </span>
                                <span className={`navbar-link ${scrolled ? 'scrolled-text' : ''}`} onClick={() => handleNavigation('/quote')}>
                                    GET A QUOTE
                                </span>
                                <span className={`navbar-link ${scrolled ? 'scrolled-text' : ''}`} onClick={() => handleNavigation('/contact')}>
                                    CONTACT US
                                </span>
                                <span className={`navbar-link ${scrolled ? 'scrolled-text' : ''}`} onClick={() => handleNavigation('/products')}>
                                    PRODUCTS
                                </span>
                                <span className={`navbar-link ${scrolled ? 'scrolled-text' : ''}`} onClick={() => handleNavigation('/manufacturing')}>
                                    MANUFACTURING
                                </span>
                                <span className={`navbar-link ${scrolled ? 'scrolled-text' : ''}`} onClick={() => handleNavigation('/ausmade')}>
                                    AUSTRALIAN MADE
                                </span>
                                <span className={`navbar-link login-link ${scrolled ? 'scrolled-text' : ''}`} onClick={() => handleNavigation('/login')}>
                                    LOGIN
                                </span>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

export default NavBar;
