import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TopBar from './TopBar';
import axios from 'axios';
import './Dashboard.css';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';

function Dashboard() {

  const url = 'https://lespeedsigns-server.australiaeast.cloudapp.azure.com:3001';
  //const url = 'http://localhost:3001';

  const navigate = useNavigate();

  // Checked devices to push update, only online devices can be checked
  const [checkedItems, setCheckedItems] = useState({});
  // Term(s) for device scroll section search box
  const [searchTerm, setSearchTerm] = useState('');
  // Devices to display in device scroll section, it contains all devices if no search performed
  const [filteredItems, setFilteredItems] = useState([]);
  const [filteredItemsLabels, setFilteredItemsLabels] = useState([]);
  // List of user's presets
  const [presets, setPresets] = useState([]);
  // Selected preset to display in the settings section
  const [activePreset, setActivePreset] = useState('');
  // Speed settings section
  const [minSpeed, setMinSpeed] = useState(-1);
  const [thresholdSpeed, setThresholdSpeed] = useState(-1);
  const [maxSpeed, setMaxSpeed] = useState(-1);
  // Below Threshold Program setting, 0 - Number mode, 1 - Image mode, 2 - Number & Image mode
  const [belowThresholdProgramNumber, setBelowThresholdProgramNumber] = useState(false);
  const [belowThresholdProgramImage, setBelowThresholdProgramImage] = useState(false);
  const [belowThresholdProgramBoth, setBelowThresholdProgramBoth] = useState(false);
  // Below Threshold Color setting, 0 - Red, 1 - Green
  const [belowThresholdColorRed, setBelowThresholdColorRed] = useState(false);
  const [belowThresholdColorGreen, setBelowThresholdColorGreen] = useState(false);
  // Below Threshold Timer settings, [detailed functionality TBD]
  const [belowThresholdTimers1, setBelowThresholdTimers1] = useState(-1);
  const [belowThresholdTimers2, setBelowThresholdTimers2] = useState(-1);
  // Below Threshold Image setting, index of image stored in device
  const [belowThresholdImage, setBelowThresholdImage] = useState(1);
  // Above Threshold Program setting, 0 - Number mode, 1 - Image mode, 2 - Number & Image mode
  const [aboveThresholdProgramNumber, setAboveThresholdProgramNumber] = useState(false);
  const [aboveThresholdProgramImage, setAboveThresholdProgramImage] = useState(false);
  const [aboveThresholdProgramBoth, setAboveThresholdProgramBoth] = useState(false);
  // Above Threshold Color setting, 0 - Red, 1 - Green
  const [aboveThresholdColorRed, setAboveThresholdColorRed] = useState(false);
  const [aboveThresholdColorGreen, setAboveThresholdColorGreen] = useState(false);
  // Above Threshold Timer settings, [detailed functionality TBD]
  const [aboveThresholdTimers1, setAboveThresholdTimers1] = useState(-1);
  const [aboveThresholdTimers2, setAboveThresholdTimers2] = useState(-1);
  // Below Threshold Image setting, index of image stored in device
  const [aboveThresholdImage, setAboveThresholdImage] = useState(2);
  // Month Schedule mode On/Off
  const [monthSchedule, setMonthSchedule] = useState(false);
  // Week Schedule mode On/Off
  const [weekSchedule, setWeekSchedule] = useState(false);
  // Month checkboxes for Month Schedule
  const [jan, setJan] = useState(false);
  const [feb, setFeb] = useState(false);
  const [mar, setMar] = useState(false);
  const [apr, setApr] = useState(false);
  const [may, setMay] = useState(false);
  const [jun, setJun] = useState(false);
  const [jul, setJul] = useState(false);
  const [aug, setAug] = useState(false);
  const [sep, setSep] = useState(false);
  const [oct, setOct] = useState(false);
  const [nov, setNov] = useState(false);
  const [dec, setDec] = useState(false);
  // Week checkboxes for Week Schedule
  const [mon, setMon] = useState(false);
  const [tue, setTue] = useState(false);
  const [wed, setWed] = useState(false);
  const [thu, setThu] = useState(false);
  const [fri, setFri] = useState(false);
  const [sat, setSat] = useState(false);
  const [sun, setSun] = useState(false);
  // Start & End time for Time Interval
  const [timeStart, setTimeStart] = useState(0);
  const [timeEnd, setTimeEnd] = useState(0);
  // Formatted Start * End time for Time Interval for better visual
  const [formatedStartTime, setFormatedStartTime] = useState('00:00');
  const [formatedEndTime, setFormatedEndTime] = useState('00:00');
  // Radar active direction, 0 - Towards, 1 - Away, 2 - 2-Ways
  const [radarDirection, setRadarDirection] = useState(0);
  const [formatedRadarDirection, setFormatedRadarDirection] = useState('Towards');
  // Radar display unit (e.g. kph, mph), currently only kph available
  const [radarUnit, setRadarUnit] = useState('');
  // Display 2/3 digits on screen for Number mode
  const [radarDigit, setRadarDigit] = useState(0);
  const [formatedRadarDigit, setFormatedRadarDigit] = useState('2-Digits');
  // Radar Sensitivity setting, higher number = less sensitivity
  const [radarSensitivity, setRadarSensitivity] = useState(0);
  // Data (screen) holding time
  const [radarHold, setRadarHold] = useState(0);
  // Currently displaying/editing preset, used as update reference
  const [currentPreset, setCurrentPreset] = useState('');
  const [flicker, setFlicker] = useState(false);
  const [loadingDeviceOnline, setLoadingDeviceOnline] = useState(false);
  const [loadingDeviceSettings, setLoadingDeviceSettings] = useState(false);

  // Options for dropdowns
  const timeOptions = ['00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'];
  const directionOptions = ['Towards', 'Away', '2-Ways'];
  const unitOptions = ['KPH'];
  const digitOptions = ['2-Digits', '3-Digits'];

  // image files
  const images = require.context('../images', true);
  const imageList = images.keys()
    .map(key => {
      const filename = key.replace('./', '');
      const index = parseInt(filename, 10);
      return { index, url: images(key) };
    })
    .sort((a, b) => a.index - b.index)
    .map(item => item.url);

  // Init server connections and retrieve data
  useEffect(() => {
    fetchDevicesData();
    fetchPresets();
    //fetchDevicesOnline();
  }, []);

  // Logged In User
  const username = sessionStorage.getItem('username');

  // If logged in fetch data, or redirect to login page
  const fetchDevicesData = async () => {
    if (username) {
      try {
        const response = await fetch(url+`/api/devices?username=${username}`);
        if (!response.ok) {
          throw new Error('Failed to fetch devices data');
        }
        const data = await response.json();
        setFilteredItems(data.devices);
      } catch (error) {
        console.error('Error fetching devices data:', error);
      }
    } else {
      // If invalid username, redirect to login
      navigate('/');
    }
    
  };

  // Fetch presets in presets dataset
  const fetchPresets = async () => {
    try {
      const response = await fetch(url+`/api/presets?username=${username}`);
      if (!response.ok) {
        throw new Error('Failed to fetch presets data');
      }
      const data = await response.json();
      setPresets(data.devices);
      if (data.devices.length > 0) {
        setActivePreset(data.devices[0].name);
      }
    } catch (error) {
      console.error('Error fetching presets:', error);
    }
  };

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async function loadingStopper() {
    await sleep(10000);
    setLoadingDeviceOnline(false);
    setLoadingDeviceSettings(false);
  }

  // Fetch devices online status by response
  const fetchDevicesOnline = async () => {
    setLoadingDeviceOnline(true);
    loadingStopper();             // MAX load 10 sec
    try {
      // Get filteredItems' labels
      for (let i = 0; i < filteredItems.length; i++) {
        if (i === 0) {
          setFilteredItemsLabels(prevState => ({
            [[filteredItems[i].label]]: !prevState[[filteredItems[i].label]]
          }));
        } else {
          setFilteredItemsLabels(prevState => ({
            ...prevState,
            [[filteredItems[i].label]]: !prevState[[filteredItems[i].label]]
          }));
        }
      };

      let labelList = "";
      for (let label in filteredItemsLabels) {
        if (labelList.length === 0) {
          labelList += label;
        } else {
          labelList += ',';
          labelList += label;
        }
      }
      // If labelList is empty, use default template
      if (labelList.length === 0) {
        labelList = "K01,K02,K03,K04,K05,K06,K07,K08,K10,K11,K12,K13,K14,K15,K16,K18,K19,K20,K21,K22,K23,K24,K25,K26,K27,K28,K29,K30,K31,K32";
      }
      
      const response = await fetch(url+`/api/online?devices=${labelList}`);
      if (!response.ok) {
        throw new Error('Failed to fetch online devices');
      }
      const data = await response.json();

      const onlineDevices = [];

      for (let key in data.devices) {
        //console.log('Found Online Device:',data.devices[key]);
        onlineDevices.push(data.devices[key]);
      }

      var tempItems = [];
      var updated = false;

      var i = 0;

      while (i < filteredItems.length) {
        var j = 0;
        updated = false;
        while (j < onlineDevices.length) {
          if (filteredItems[i].label === onlineDevices[j]) {
            tempItems.push({id: filteredItems[i].id, username: filteredItems[i].username, label: filteredItems[i].label, note: filteredItems[i].note, addr: filteredItems[i].addr, lat: filteredItems[i].lat, lon: filteredItems[i].lon, connected: 1});
            updated = true;
          }
          j++;
        }
        if (!updated) {
          tempItems.push({id: filteredItems[i].id, username: filteredItems[i].username, label: filteredItems[i].label, note: filteredItems[i].note, addr: filteredItems[i].addr, lat: filteredItems[i].lat, lon: filteredItems[i].lon, connected: 0});
        }

        i++;
      };
      setFilteredItems(tempItems);
      setLoadingDeviceOnline(false);
    } catch (error) {
      console.error('Error fetching online devices:', error);
    }
  }

  // Set parameters for selected preset
  useEffect(() => {
    if (activePreset) {
      const foundPreset = presets.find(preset => preset.name === activePreset);
      if (foundPreset) {
        setCurrentPreset(foundPreset.presetid);

        // Fetch Speed Settings parameters
        setMinSpeed(foundPreset.minSpeed);
        setThresholdSpeed(foundPreset.thresholdSpeed);
        setMaxSpeed(foundPreset.maxSpeed);
        
        // Fetch Below Threshold Program parameters
        // 0 - number mode, 1 - image mode, 2 - number & image mode
        if (foundPreset.belowProgram === 0) {
          setBelowThresholdProgramNumber(true);
          setBelowThresholdProgramImage(false);
          setBelowThresholdProgramBoth(false);
        } else if (foundPreset.belowProgram === 1) {
          setBelowThresholdProgramNumber(false);
          setBelowThresholdProgramImage(true);
          setBelowThresholdProgramBoth(false);
        } else if (foundPreset.belowProgram === 2) {
          setBelowThresholdProgramNumber(false);
          setBelowThresholdProgramImage(false);
          setBelowThresholdProgramBoth(true);
        }
        
        // Fetch Below Threshold Color parameters
        // 0 - red, 1 - green
        if (foundPreset.belowColor === 0) {
          setBelowThresholdColorRed(true);
          setBelowThresholdColorGreen(false);
        } else if (foundPreset.belowColor === 1) {
          setBelowThresholdColorRed(false);
          setBelowThresholdColorGreen(true);
        }

        // Fetch Below Threshold Timers parameter
        setBelowThresholdTimers1(foundPreset.belowTimer1);
        setBelowThresholdTimers2(foundPreset.belowTimer2);

        // Fetch Below Threshold Image parameter
        setBelowThresholdImage(foundPreset.belowContent);
        
        // Fetch Above Threshold Program parameters
        // 0 - number mode, 1 - image mode, 2 - number & image mode
        if (foundPreset.overProgram === 0) {
          setAboveThresholdProgramNumber(true);
          setAboveThresholdProgramImage(false);
          setAboveThresholdProgramBoth(false);
        } else if (foundPreset.overProgram === 1) {
          setAboveThresholdProgramNumber(false);
          setAboveThresholdProgramImage(true);
          setAboveThresholdProgramBoth(false);
        } else if (foundPreset.overProgram === 2) {
          setAboveThresholdProgramNumber(false);
          setAboveThresholdProgramImage(false);
          setAboveThresholdProgramBoth(true);
        }

        // Fetch Above Threshold Color parameters
        // 0 - red, 1 - green
        if (foundPreset.overColor === 0) {
          setAboveThresholdColorRed(true);
          setAboveThresholdColorGreen(false);
        } else if (foundPreset.overColor === 1) {
          setAboveThresholdColorRed(false);
          setAboveThresholdColorGreen(true);
        }

        // Fetch Above Threshold Timers parameter
        setAboveThresholdTimers1(foundPreset.overTimer1);
        setAboveThresholdTimers2(foundPreset.overTimer2);

        // Fetch Above Threshold Image parameter
        setAboveThresholdImage(foundPreset.overContent);

        // Set month schedules
        if (foundPreset.timePeriodMonth === 1) {
          setMonthSchedule(true); // Assuming you have a state for month schedule
        } else {
          setMonthSchedule(false);
        }

        // Set week schedules
        if (foundPreset.timePeriodWeek === 1) {
          setWeekSchedule(true);
        } else {
          setWeekSchedule(false);
        }

        // Set Month Checkboxes status
        setJan(foundPreset.monthJan);
        setFeb(foundPreset.monthFeb);
        setMar(foundPreset.monthMar);
        setApr(foundPreset.monthApr);
        setMay(foundPreset.monthMay);
        setJun(foundPreset.monthJun);
        setJul(foundPreset.monthJul);
        setAug(foundPreset.monthAug);
        setSep(foundPreset.monthSep);
        setOct(foundPreset.monthOct);
        setNov(foundPreset.monthNov);
        setDec(foundPreset.monthDec);

        // Set Week Checkboxes status
        setMon(foundPreset.weekMon);
        setTue(foundPreset.weekTue);
        setWed(foundPreset.weekWed);
        setThu(foundPreset.weekThu);
        setFri(foundPreset.weekFri);
        setSat(foundPreset.weekSat);
        setSun(foundPreset.weekSun);

        // Set Time Interval parameters
        setTimeStart(foundPreset.timeStart);
        setTimeEnd(foundPreset.timeEnd);

        // Fetch timer and set formated start & end time
        setTimeStart(foundPreset.timeStart);
        setTimeEnd(foundPreset.timeEnd);
        if (foundPreset.timeStart === 0) {
          setFormatedStartTime('00:00');
        } else if (foundPreset.timeStart === 1) {
          setFormatedStartTime('01:00');
        } else if (foundPreset.timeStart === 2) {
          setFormatedStartTime('02:00');
        } else if (foundPreset.timeStart === 3) {
          setFormatedStartTime('03:00');
        } else if (foundPreset.timeStart === 4) {
          setFormatedStartTime('04:00');
        } else if (foundPreset.timeStart === 5) {
          setFormatedStartTime('05:00');
        } else if (foundPreset.timeStart === 6) {
          setFormatedStartTime('06:00');
        } else if (foundPreset.timeStart === 7) {
          setFormatedStartTime('07:00');
        } else if (foundPreset.timeStart === 8) {
          setFormatedStartTime('08:00');
        } else if (foundPreset.timeStart === 9) {
          setFormatedStartTime('09:00');
        } else if (foundPreset.timeStart === 10) {
          setFormatedStartTime('10:00');
        } else if (foundPreset.timeStart === 11) {
          setFormatedStartTime('11:00');
        } else if (foundPreset.timeStart === 12) {
          setFormatedStartTime('12:00');
        } else if (foundPreset.timeStart === 13) {
          setFormatedStartTime('13:00');
        } else if (foundPreset.timeStart === 14) {
          setFormatedStartTime('14:00');
        } else if (foundPreset.timeStart === 15) {
          setFormatedStartTime('15:00');
        } else if (foundPreset.timeStart === 16) {
          setFormatedStartTime('16:00');
        } else if (foundPreset.timeStart === 17) {
          setFormatedStartTime('17:00');
        } else if (foundPreset.timeStart === 18) {
          setFormatedStartTime('28:00');
        } else if (foundPreset.timeStart === 19) {
          setFormatedStartTime('19:00');
        } else if (foundPreset.timeStart === 20) {
          setFormatedStartTime('20:00');
        } else if (foundPreset.timeStart === 21) {
          setFormatedStartTime('21:00');
        } else if (foundPreset.timeStart === 22) {
          setFormatedStartTime('22:00');
        } else if (foundPreset.timeStart === 23) {
          setFormatedStartTime('23:00');
        }

        if (foundPreset.timeEnd === 0) {
          setFormatedEndTime('00:00');
        } else if (foundPreset.timeEnd === 1) {
          setFormatedEndTime('01:00');
        } else if (foundPreset.timeEnd === 2) {
          setFormatedEndTime('02:00');
        } else if (foundPreset.timeEnd === 3) {
          setFormatedEndTime('03:00');
        } else if (foundPreset.timeEnd === 4) {
          setFormatedEndTime('04:00');
        } else if (foundPreset.timeEnd === 5) {
          setFormatedEndTime('05:00');
        } else if (foundPreset.timeEnd === 6) {
          setFormatedEndTime('06:00');
        } else if (foundPreset.timeEnd === 7) {
          setFormatedEndTime('07:00');
        } else if (foundPreset.timeEnd === 8) {
          setFormatedEndTime('08:00');
        } else if (foundPreset.timeEnd === 9) {
          setFormatedEndTime('09:00');
        } else if (foundPreset.timeEnd === 10) {
          setFormatedEndTime('10:00');
        } else if (foundPreset.timeEnd === 11) {
          setFormatedEndTime('11:00');
        } else if (foundPreset.timeEnd === 12) {
          setFormatedEndTime('12:00');
        } else if (foundPreset.timeEnd === 13) {
          setFormatedEndTime('13:00');
        } else if (foundPreset.timeEnd === 14) {
          setFormatedEndTime('14:00');
        } else if (foundPreset.timeEnd === 15) {
          setFormatedEndTime('15:00');
        } else if (foundPreset.timeEnd === 16) {
          setFormatedEndTime('16:00');
        } else if (foundPreset.timeEnd === 17) {
          setFormatedEndTime('17:00');
        } else if (foundPreset.timeEnd === 18) {
          setFormatedEndTime('28:00');
        } else if (foundPreset.timeEnd === 19) {
          setFormatedEndTime('19:00');
        } else if (foundPreset.timeEnd === 20) {
          setFormatedEndTime('20:00');
        } else if (foundPreset.timeEnd === 21) {
          setFormatedEndTime('21:00');
        } else if (foundPreset.timeEnd === 22) {
          setFormatedEndTime('22:00');
        } else if (foundPreset.timeEnd === 23) {
          setFormatedEndTime('23:00');
        }

        // Fetch Radar Direction" 0 - Towards, 1 - Away, 2 - 2-Ways
        setRadarDirection(foundPreset.radarDirection);
        if (foundPreset.radarDirection === 0) {
          setFormatedRadarDirection('Towards');
        } else if (foundPreset.radarDirection === 1) {
          setFormatedRadarDirection('Away');
        } else if (foundPreset.radarDirection === 2) {
          setFormatedRadarDirection('2-Ways');
        }

        // Fetch Radar Digit
        setRadarDigit(foundPreset.radardigit);
        if (foundPreset.radardigit === 2) {
          setFormatedRadarDigit('2-Digits');
        } else if (foundPreset.radardigit === 3) {
          setFormatedRadarDigit('3-Digits')
        }

        // Fetch Radar Sensitivity
        setRadarSensitivity(foundPreset.sensitivity);

        // Fetch Screen Hold time
        setRadarHold(foundPreset.hold);

        // Fetch Flicker On/Off
        setFlicker(foundPreset.flicker);
      }
    }
  }, [activePreset]);

  const handleCheckboxChange = (itemLabel) => {
    const item = filteredItems.find(device => device.label === itemLabel);
    if (item && item.connected) {
        setCheckedItems(prevState => {
          /*
          // If the item was checked, uncheck it by removing it from the state
          if (prevState[itemLabel]) {
              const { [itemLabel]: _, ...rest } = prevState;
              return rest;
          } else {
              // Otherwise, add the item to the checkedItems
              return {
                  ...prevState,
                  [itemLabel]: true
              };
          }
          */
          
          // Only select one device
          return {
            [itemLabel]: true
          }
        });
    } else {
        alert('You can only check devices that are connected');
    }
};
  
  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
    if (searchTerm === '') {
      handleClearSearch();
    } else {
      const filtered = filteredItems.filter(item =>
        item.label.toLowerCase().includes(searchTerm.toLowerCase()) || item.note.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredItems(filtered);
    }
  };
  
  const handleClearSearch = () => {
    setSearchTerm('');
    fetchDevicesData();
  };

  const toggleSelectAll = () => {
    const newCheckedItems = {};
    if (Object.keys(checkedItems).length === filteredItems.filter(item => item.connected).length) {
      setCheckedItems({});
      filteredItems.forEach(item => {
        if (item.connected) {
          newCheckedItems[item.id] = true;
        }
      });
    } else {
      filteredItems.forEach(item => {
        if (item.connected) {
          newCheckedItems[item.id] = false;
        }
      });
      setCheckedItems(newCheckedItems);
    }
  };

  const handlePresetClick = (presetName) => {
    setActivePreset(presetName);
  };

  const handleMinSpeedChange = (value) => {
    setMinSpeed(value);
  };

  const handleThresholdChange = (value) => {
    setThresholdSpeed(value);
  };

  const handleBelowThresholdProgramChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setBelowThresholdProgramNumber(name === 'number');
      setBelowThresholdProgramImage(name === 'image');
      setBelowThresholdProgramBoth(name === 'both');
    }
  };

  const handleBelowThresholdColorChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setBelowThresholdColorRed(name === 'red');
      setBelowThresholdColorGreen(name === 'green');
    }
  };

  const handleBelowThresholdTimerChange = (timer, value) => {
    if (timer === 'timer1') {
      setBelowThresholdTimers1(value);
    } else if (timer === 'timer2') {
      setBelowThresholdTimers2(value);
    }
  };

  const handleBelowThresholdImageChange = (event) => {
    const selectedImage = event.target.value;
    setBelowThresholdImage(selectedImage);
  };

  // Handlers for Above Threshold Settings
  const handleAboveThresholdProgramChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setAboveThresholdProgramNumber(name === 'number');
      setAboveThresholdProgramImage(name === 'image');
      setAboveThresholdProgramBoth(name === 'both');
    }
  };

  const handleAboveThresholdColorChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setAboveThresholdColorRed(name === 'red');
      setAboveThresholdColorGreen(name === 'green');
    }
  };

  const handleAboveThresholdTimerChange = (timer, value) => {
    if (timer === 'timer1') {
      setAboveThresholdTimers1(value);
    } else if (timer === 'timer2') {
      setAboveThresholdTimers2(value);
    }
  };

  const handleAboveThresholdImageChange = (event) => {
    const selectedImage = event.target.value;
    setAboveThresholdImage(selectedImage);
  };

  const handleTimeStartChange = (event) => {
    const selectedTime = event.target.value;
    setFormatedStartTime(selectedTime);
    if (selectedTime === '00:00') {
      setTimeStart(0);
    } else if (selectedTime === '01:00') {
      setTimeStart(1);
    } else if (selectedTime === '02:00') {
      setTimeStart(2);
    } else if (selectedTime === '03:00') {
      setTimeStart(3);
    } else if (selectedTime === '04:00') {
      setTimeStart(4);
    } else if (selectedTime === '05:00') {
      setTimeStart(5);
    } else if (selectedTime === '06:00') {
      setTimeStart(6);
    } else if (selectedTime === '07:00') {
      setTimeStart(7);
    } else if (selectedTime === '08:00') {
      setTimeStart(8);
    } else if (selectedTime === '09:00') {
      setTimeStart(9);
    } else if (selectedTime === '10:00') {
      setTimeStart(10);
    } else if (selectedTime === '11:00') {
      setTimeStart(11);
    } else if (selectedTime === '12:00') {
      setTimeStart(12);
    } else if (selectedTime === '13:00') {
      setTimeStart(13);
    } else if (selectedTime === '14:00') {
      setTimeStart(14);
    } else if (selectedTime === '15:00') {
      setTimeStart(15);
    } else if (selectedTime === '16:00') {
      setTimeStart(16);
    } else if (selectedTime === '17:00') {
      setTimeStart(17);
    } else if (selectedTime === '18:00') {
      setTimeStart(18);
    } else if (selectedTime === '19:00') {
      setTimeStart(19);
    } else if (selectedTime === '20:00') {
      setTimeStart(20);
    } else if (selectedTime === '21:00') {
      setTimeStart(21);
    } else if (selectedTime === '22:00') {
      setTimeStart(22);
    } else if (selectedTime === '23:00') {
      setTimeStart(23);
    }
  };
  
  const handleTimeEndChange = (event) => {
    const selectedTime = event.target.value;
    setFormatedEndTime(selectedTime);
    if (selectedTime === '00:00') {
      setTimeEnd(0);
    } else if (selectedTime === '01:00') {
      setTimeEnd(1);
    } else if (selectedTime === '02:00') {
      setTimeEnd(2);
    } else if (selectedTime === '03:00') {
      setTimeEnd(3);
    } else if (selectedTime === '04:00') {
      setTimeEnd(4);
    } else if (selectedTime === '05:00') {
      setTimeEnd(5);
    } else if (selectedTime === '06:00') {
      setTimeEnd(6);
    } else if (selectedTime === '07:00') {
      setTimeEnd(7);
    } else if (selectedTime === '08:00') {
      setTimeEnd(8);
    } else if (selectedTime === '09:00') {
      setTimeEnd(9);
    } else if (selectedTime === '10:00') {
      setTimeEnd(10);
    } else if (selectedTime === '11:00') {
      setTimeEnd(11);
    } else if (selectedTime === '12:00') {
      setTimeEnd(12);
    } else if (selectedTime === '13:00') {
      setTimeEnd(13);
    } else if (selectedTime === '14:00') {
      setTimeEnd(14);
    } else if (selectedTime === '15:00') {
      setTimeEnd(15);
    } else if (selectedTime === '16:00') {
      setTimeEnd(16);
    } else if (selectedTime === '17:00') {
      setTimeEnd(17);
    } else if (selectedTime === '18:00') {
      setTimeEnd(18);
    } else if (selectedTime === '19:00') {
      setTimeEnd(19);
    } else if (selectedTime === '20:00') {
      setTimeEnd(20);
    } else if (selectedTime === '21:00') {
      setTimeEnd(21);
    } else if (selectedTime === '22:00') {
      setTimeEnd(22);
    } else if (selectedTime === '23:00') {
      setTimeEnd(23);
    }
  };
  

  const handleDirectionChange = (event) => {
    setFormatedRadarDirection(event.target.value);

    if (event.target.value === 'Towards') {
      setRadarDirection(0);
    } else if (event.target.value === 'Away') {
      setRadarDirection(1);
    } else if (event.target.value === '2-Ways') {
      setRadarDirection(2);
    }

    console.log(radarDirection);
  };
  
  const handleUnitChange = (event) => {
    setRadarUnit(event.target.value);
  };
  
  const handleDigitChange = (event) => {
    setFormatedRadarDigit(event.target.value);
    if (event.target.value === '2-Digits') {
      setRadarDigit(2);
    } else if (event.target.value === '3-Digits') {
      setRadarDigit(3);
    }
  };

  const handleSensitivityChange = (value) => {
    setRadarSensitivity(value);
  };
  
  const handleHoldChange = (value) => {
    setRadarHold(value);
  };

  const handleSavePreset = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post(url+'/api/save', {
          checkedItems, currentPreset, minSpeed, thresholdSpeed, maxSpeed, 
          belowThresholdProgramNumber,belowThresholdProgramImage, belowThresholdProgramBoth,
          belowThresholdColorRed, belowThresholdColorGreen,
          belowThresholdTimers1, belowThresholdTimers2, belowThresholdImage,
          aboveThresholdProgramNumber,aboveThresholdProgramImage, aboveThresholdProgramBoth,
          aboveThresholdColorRed, aboveThresholdColorGreen,
          aboveThresholdTimers1, aboveThresholdTimers2, aboveThresholdImage,
          radarDirection, radarDigit, radarSensitivity, radarHold,
          monthSchedule, weekSchedule,
          jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec,
          mon, tue, wed, thu, fri, sat, sun, timeStart, timeEnd
        });

      if (response.status === 200) {
        alert("Preset Saved Successfully!");
        /*
        if (!alert("Preset Saved Successfully!")){
          window.location.reload();
        }
          */
      } else {
        alert('Network error. Please try again.');
      }
    } catch (error) {
      console.error('Error save preset:', error);
      alert('Failed to save. Please try again later.');
    }
  };

  const handlePushUpdate = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(url+'/api/push_update', {
        flicker, checkedItems, currentPreset, minSpeed, thresholdSpeed, maxSpeed, 
        belowThresholdProgramNumber,belowThresholdProgramImage, belowThresholdProgramBoth,
        belowThresholdColorRed, belowThresholdColorGreen,
        belowThresholdTimers1, belowThresholdTimers2, belowThresholdImage,
        aboveThresholdProgramNumber,aboveThresholdProgramImage, aboveThresholdProgramBoth,
        aboveThresholdColorRed, aboveThresholdColorGreen,
        aboveThresholdTimers1, aboveThresholdTimers2, aboveThresholdImage,
        radarDirection, radarDigit, radarSensitivity, radarHold,
        monthSchedule, weekSchedule,
        jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec,
        mon, tue, wed, thu, fri, sat, sun, timeStart, timeEnd
      });


      if (response.status === 200) {
        if (!alert("Update Sent!")){/*window.location.reload();*/}
      } else {
        alert('Network error. Please try again.');
      }
    } catch (error) {
      console.error('Error save preset:', error);
      //alert('Failed to update. Please try again later.');
    }
  };

  const handleRefreshDevices = () => {
    fetchDevicesOnline();
  };

  const handleGetSettings = async () => {
    var i = 0;
    Object.keys(checkedItems).forEach((item) => { i++ });
  
    if (i !== 1) {
      alert("Error: Please select ONE device to retrieve settings.");
    } else {

      setLoadingDeviceSettings(true);
      loadingStopper(); 

      // Get device id
      var label = '';
      Object.keys(checkedItems).forEach((item) => {
        label = item;
      });

      try {
        const response = await fetch(url+`/api/settings?device=${label}`);
        if (!response.ok) {
          throw new Error('Failed to fetch device settings');
        }

        const data = await response.json();
        
        setMinSpeed(data.minSpeed);
        setThresholdSpeed(data.thresholdSpeed);
        setMaxSpeed(data.maxSpeed);
        setBelowThresholdProgramNumber(data.belowThresholdProgramNumber);
        setBelowThresholdProgramImage(data.belowThresholdProgramImage);
        setBelowThresholdProgramBoth(data.belowThresholdProgramBoth);
        setBelowThresholdColorRed(data.belowThresholdColorRed);
        setBelowThresholdColorGreen(data.belowThresholdColorGreen);
        setBelowThresholdTimers1(data.belowThresholdTimers1);
        setBelowThresholdTimers2(data.belowThresholdTimers2);
        setBelowThresholdImage(data.belowThresholdImage);
        setAboveThresholdProgramNumber(data.aboveThresholdProgramNumber);
        setAboveThresholdProgramImage(data.aboveThresholdProgramImage);
        setAboveThresholdProgramBoth(data.aboveThresholdProgramBoth);
        setAboveThresholdColorRed(data.aboveThresholdColorRed);
        setAboveThresholdColorGreen(data.aboveThresholdColorGreen);
        setAboveThresholdTimers1(data.aboveThresholdTimers1);
        setAboveThresholdTimers2(data.aboveThresholdTimers2);
        setAboveThresholdImage(data.aboveThresholdImage);
        setRadarDirection(data.radarDirection);
        setRadarDigit(data.radarDigit);
        setRadarSensitivity(data.radarSensitivity);
        setRadarHold(data.radarHold);
        setFlicker(data.flicker);

        setLoadingDeviceSettings(false);
        alert("Retrieve Device Setting Successful!");



      } catch (error) {
        console.error('Error fetching device settings:', error);
      }
        
    }
  };


  return (
    <div className="dashboard">
      <TopBar />
      <div className="preset-tabs">
        {presets.map(preset => (
          <div
            key={preset.name}
            className={`preset-tab ${activePreset === preset.name ? 'active' : ''}`}
            onClick={() => handlePresetClick(preset.name)}
          >
            {preset.name}
          </div>
        ))}
      </div>
      <div className="main-content">
        <div className="content-body">
          <div className="layout">
            <div className="scrolling-section">
              <div className="search-container">
                <input
                  type="text"
                  placeholder="Search..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
                {searchTerm && (
                  <button className="clear-search-btn" onClick={handleClearSearch}>
                    Clear
                  </button>
                )}
              </div>
              {/* 
              <div className="scrolling-item select-all-tem">
                <label className="select-all-label">
                  <input
                    type="checkbox"i
                    id="selectAll"
                    checked={Object.keys(checkedItems).length === filteredItems.filter(item => item.connected).length}
                    onChange={toggleSelectAll}
                  />
                  Select All
                </label>
              </div>
              */}
              {filteredItems.map((item) => (
                <div key={item.id} className="scroll-item">
                  <div className="device-info">
                    <input
                      type="checkbox"
                      id={item.id}
                      checked={checkedItems[item.label] || false}
                      onChange={() => handleCheckboxChange(item.label)}
                      disabled={!item.connected}
                    />
                    <label htmlFor={item.id}><b>{item.label}:</b> {item.note}</label>
                    <div className={`status-icon ${item.connected ? 'connected' : 'disconnected'}`}></div>
                  </div>
                  <div className="note-line">Address: {item.addr}</div>
                  <div className="note-line">S/N: {item.id}</div>
                </div>
              ))}
            </div>
            <div className="main-section">
              <div className="settings-box">
                <div className="settings-row">
                  <div className="settings-cell">
                    <div className="settings-title">Speed Settings</div>
                    <div className="settings-group">
                      <div className="settings-row">
                        <div className="settings-cell">
                          <div className="settings-title">
                            Screen On Speed
                            <Tooltip title="The minimum speed for screen to turn on.">
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                          </div>
                          <input
                            type="number"
                            className="input-number"
                            value={minSpeed}
                            onChange={(e) => handleMinSpeedChange(parseInt(e.target.value))}
                          />
                          <span className="speed-unit"> km/h</span>
                        </div>
                      </div>
                      <div className="settings-row">
                        <div className="settings-cell">
                          <div className="settings-title">
                            Over Speed
                            <Tooltip title="The speed for screen to switch from 'Below Speed Mode' to 'Above Speed Mode'.">
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <input
                            type="number"
                            className="input-number"
                            value={thresholdSpeed}
                            onChange={(e) => handleThresholdChange(parseInt(e.target.value))}
                          />
                          <span className="speed-unit"> km/h</span>
                        </div>
                      </div>
                      <div className="settings-row">
                        <div className="settings-cell">
                          <div className="settings-title">Flicker Mode</div>
                          <label className="switch">
                            <input type="checkbox" checked={flicker} onChange={() => setFlicker(!flicker)} />
                            <span className="slider"></span>
                          </label>
                        </div>
                        
                      </div>
                    </div>
                  </div>
                  <div className="settings-cell-green">
                    <div className="settings-title">Below Speed Settings</div>
                    <div className="settings-group">
                      <div className="settings-row">
                        <div className="settings-cell">
                          <div className="radio-buttons">
                            <div className="settings-title">
                              Program
                            </div>
                            <label>
                              <input
                                type="checkbox"
                                name="number"
                                checked={belowThresholdProgramNumber}
                                onChange={handleBelowThresholdProgramChange}
                              />
                              Speed
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="image"
                                checked={belowThresholdProgramImage}
                                onChange={handleBelowThresholdProgramChange}
                              />
                              Image
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="both"
                                checked={belowThresholdProgramBoth}
                                onChange={handleBelowThresholdProgramChange}
                              />
                              Both
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="settings-row">
                        <div className="settings-cell">
                          <div className="radio-buttons">
                            <div className="settings-title">
                              Speed Program Color
                              <Tooltip title="Speed Program Color can only be adjusted in Program Speed.">
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <label>
                              <input
                                type="checkbox"
                                name="red"
                                checked={belowThresholdColorRed}
                                onChange={handleBelowThresholdColorChange}
                                disabled={belowThresholdProgramImage}
                                background-color={!belowThresholdProgramImage ? '#808080': '#0000FF'}
                              />
                              Red
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="green"
                                checked={belowThresholdColorGreen}
                                onChange={handleBelowThresholdColorChange}
                                disabled={belowThresholdProgramImage}
                                background-color={!belowThresholdProgramImage ? '#808080': '#0000FF'}
                              />
                              Green
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="settings-row">
                        <div className="settings-cell">
                          <div className="settings-title">
                            Flash Cycle Timer
                            <Tooltip title="Flash Cycle Timer for Program Both.">
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div className="timers-section">
                            <div className="timer">
                              <span>On Timer:</span>
                              <input
                                type="number"
                                className="input-number"
                                value={belowThresholdTimers1}
                                onChange={(e) => handleBelowThresholdTimerChange('timer1', parseInt(e.target.value))}
                              />
                              <span className="speed-unit"> sec</span>
                            </div>
                            <div className="timer">
                              <span>Off Timer:</span>
                              <input
                                type="number"
                                className="input-number"
                                value={belowThresholdTimers2}
                                onChange={(e) => handleBelowThresholdTimerChange('timer2', parseInt(e.target.value))}
                              />
                              <span className="speed-unit"> sec</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="settings-cell-green">
                    <div className="settings-title">Below Speed Image</div>
                    <select
                      value={belowThresholdImage}
                      onChange={handleBelowThresholdImageChange}
                    >
                      {imageList
                      .slice()
                      .sort((a, b) => {
                        const numA = parseInt(a.match(/\d+/)[0], 10);
                        const numB = parseInt(b.match(/\d+/)[0], 10);
                        return numA - numB;
                      })
                      .map((image, index) => (
                        <option key={index + 1} value={index + 1}>
                          Image {index + 1}
                        </option>
                      ))}
                    </select>
                    {belowThresholdImage !== '' && (
                      <div>
                        <h4>Image Preview:</h4>
                        <div className="image-container-preview">
                          <img src={imageList[belowThresholdImage - 1]} />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="settings-cell-red">
                    <div className="settings-title">Above Speed Settings</div>
                    <div className="settings-group">
                      <div className="settings-row">
                        <div className="settings-cell">
                          <div className="radio-buttons">
                            <div className="settings-title">
                              Program
                            </div>
                            <label>
                              <input
                                type="checkbox"
                                name="number"
                                checked={aboveThresholdProgramNumber}
                                onChange={handleAboveThresholdProgramChange}
                              />
                              Speed
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="image"
                                checked={aboveThresholdProgramImage}
                                onChange={handleAboveThresholdProgramChange}
                              />
                              Image
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="both"
                                checked={aboveThresholdProgramBoth}
                                onChange={handleAboveThresholdProgramChange}
                              />
                              Both
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="settings-row">
                        <div className="settings-cell">
                          <div className="radio-buttons">
                            <div className="settings-title">
                              Speed Program Color
                              <Tooltip title="Speed Program Color can only be adjusted in Program Speed.">
                                <IconButton>
                                  <InfoIcon />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <label>
                              <input
                                type="checkbox"
                                name="red"
                                checked={aboveThresholdColorRed}
                                onChange={handleAboveThresholdColorChange}
                                disabled={aboveThresholdProgramImage}
                                background-color={!aboveThresholdProgramImage ? '#808080': '#0000FF'}
                              />
                              Red
                            </label>
                            <label>
                              <input
                                type="checkbox"
                                name="green"
                                checked={aboveThresholdColorGreen}
                                onChange={handleAboveThresholdColorChange}
                                disabled={aboveThresholdProgramImage}
                                background-color={!aboveThresholdProgramImage ? '#808080': '#0000FF'}
                              />
                              Green
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="settings-row">
                        <div className="settings-cell">
                          <div className="settings-title">
                            Flash Cycle Timer
                            <Tooltip title="Flash Cycle Timer for Program Both.">
                              <IconButton>
                                <InfoIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                          <div className="timers-section">
                            <div className="timer">
                              <span>On Timer:</span>
                              <input
                                type="number"
                                className="input-number"
                                value={aboveThresholdTimers1}
                                onChange={(e) => handleAboveThresholdTimerChange('timer1', parseInt(e.target.value))}
                              />
                              <span className="speed-unit"> sec</span>
                            </div>
                            <div className="timer">
                              <span>Off Timer:</span>
                              <input
                                type="number"
                                className="input-number"
                                value={aboveThresholdTimers2}
                                onChange={(e) => handleAboveThresholdTimerChange('timer2', parseInt(e.target.value))}
                              />
                              <span className="speed-unit"> sec</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="settings-cell-red">
                    <div className="settings-title">Above Speed Image</div>
                    <select
                      value={aboveThresholdImage}
                      onChange={handleAboveThresholdImageChange}
                    >
                      {imageList
                      .slice()
                      .sort((a, b) => {
                        const numA = parseInt(a.match(/\d+/)[0], 10);
                        const numB = parseInt(b.match(/\d+/)[0], 10);
                        return numA - numB;
                      })
                      .map((image, index) => (
                        <option key={index + 1} value={index + 1}>
                          Image {index + 1}
                        </option>
                      ))}
                    </select>
                    {aboveThresholdImage !== '' && (
                      <div>
                        <h4>Image Preview:</h4>
                        <div className="image-container-preview">
                          <img src={imageList[aboveThresholdImage - 1]} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='settings-box'>
                <div className="settings-row">
                  <div className="settings-cell">
                    <div className="settings-title">Month Schedule</div>
                    <div className="month-settings">
                      <label className="switch">
                        {/* TODO: Disabled Month Schedule On/Off */}
                        {/*<input type="checkbox" checked={monthSchedule} onChange={() => setMonthSchedule(!monthSchedule)} />*/}
                        <input type="checkbox" checked={monthSchedule} />
                        <span className="slider"></span>
                      </label>
                      <div className="month-checkboxes">
                        <div className="checkbox-row">
                          <label><input type="checkbox" checked={jan} onChange={() => setJan(!jan)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> Jan</label>
                          <label><input type="checkbox" checked={feb} onChange={() => setFeb(!feb)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> Feb</label>
                          <label><input type="checkbox" checked={mar} onChange={() => setMar(!mar)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> Mar</label>
                          <label><input type="checkbox" checked={apr} onChange={() => setApr(!apr)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> Apr</label>
                          <label><input type="checkbox" checked={may} onChange={() => setMay(!may)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> May</label>
                          <label><input type="checkbox" checked={jun} onChange={() => setJun(!jun)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> Jun</label>
                        </div>
                        <div className="checkbox-row">
                          <label><input type="checkbox" checked={jul} onChange={() => setJul(!jul)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> Jul</label>
                          <label><input type="checkbox" checked={aug} onChange={() => setAug(!aug)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> Aug</label>
                          <label><input type="checkbox" checked={sep} onChange={() => setSep(!sep)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> Sep</label>
                          <label><input type="checkbox" checked={oct} onChange={() => setOct(!oct)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> Oct</label>
                          <label><input type="checkbox" checked={nov} onChange={() => setNov(!nov)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> Nov</label>
                          <label><input type="checkbox" checked={dec} onChange={() => setDec(!dec)} disabled={!monthSchedule} background-color={!monthSchedule ? '#808080': '#0000FF'} /> Dec</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="settings-cell">
                    <div className="settings-title">Week Schedule</div>
                    <div className="week-settings">
                      <label className="switch">
                        {/* TODO: Disabled Week Schedule On/Off */}
                        {/*<input type="checkbox" checked={weekSchedule} onChange={() => setWeekSchedule(!weekSchedule)} />*/}
                        <input type="checkbox" checked={weekSchedule} />
                        <span className="slider"></span>
                      </label>
                      
                      <div className="week-checkboxes">
                        <label><input type="checkbox" checked={sun} onChange={() => setSun(!sun)} disabled={!weekSchedule} background-color={!weekSchedule ? '#808080': '#0000FF'} /> Sun</label>
                        <label><input type="checkbox" checked={mon} onChange={() => setMon(!mon)} disabled={!weekSchedule} background-color={!weekSchedule ? '#808080': '#0000FF'} /> Mon</label>
                        <label><input type="checkbox" checked={tue} onChange={() => setTue(!tue)} disabled={!weekSchedule} background-color={!weekSchedule ? '#808080': '#0000FF'} /> Tue</label>
                        <label><input type="checkbox" checked={wed} onChange={() => setWed(!wed)} disabled={!weekSchedule} background-color={!weekSchedule ? '#808080': '#0000FF'} /> Wed</label>
                        <label><input type="checkbox" checked={thu} onChange={() => setThu(!thu)} disabled={!weekSchedule} background-color={!weekSchedule ? '#808080': '#0000FF'} /> Thu</label>
                        <label><input type="checkbox" checked={fri} onChange={() => setFri(!fri)} disabled={!weekSchedule} background-color={!weekSchedule ? '#808080': '#0000FF'} /> Fri</label>
                        <label><input type="checkbox" checked={sat} onChange={() => setSat(!sat)} disabled={!weekSchedule} background-color={!weekSchedule ? '#808080': '#0000FF'} /> Sat</label>
                      </div>
                    </div>
                  </div>
                  <div className="settings-cell">
                    <div className="settings-title">
                      Time Interval
                      <Tooltip title="Time Interval is functional when Month and/or Week Schedule Enabled.">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                      </div>
                    <div className="time-interval-settings">
                    <div className="time-dropdown">
                      <label>Starts:</label>
                        <select
                          value={formatedStartTime}
                          onChange={(e) => handleTimeStartChange(e)}
                        >
                          {timeOptions.map(option => (
                            <option key={option} value={option}>{option}</option>
                          ))}
                        </select>
                        <label>, Ends:</label>
                        <select
                          value={formatedEndTime}
                          onChange={(e) => handleTimeEndChange(e)}
                        >
                          {timeOptions.map(option => (
                            <option key={option} value={option}>{option}</option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='settings-box'>
                <div className="settings-row">
                  <div className="settings-cell">
                    <div className="settings-title">
                      Radar Settings
                      <Tooltip title="Sensitivity: 1 - Most Sensitive, 15 - Least Sensitive">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </div>
                    <div className="settings-group">
                      <div className="settings-row">
                        <div className="settings-cell">
                          <div className="settings-title">Direction</div>
                          <select
                            value={formatedRadarDirection}
                            onChange={handleDirectionChange}
                          >
                            {directionOptions.map(option => (
                              <option key={option} value={option}>{option}</option>
                            ))}
                          </select>
                        </div>
                        <div className="settings-cell">
                          <div className="settings-title">Unit</div>
                          <select
                            value={radarUnit}
                            onChange={handleUnitChange}
                          >
                            {unitOptions.map(option => (
                              <option key={option} value={option}>{option}</option>
                            ))}
                          </select>
                        </div>
                        <div className="settings-cell">
                          <div className="settings-title">Digit</div>
                          <select
                            value={formatedRadarDigit}
                            onChange={handleDigitChange}
                          >
                            {digitOptions.map(option => (
                              <option key={option} value={option}>{option}</option>
                            ))}
                          </select>
                        </div>
                        <div className="settings-cell">
                          <div className="settings-title">
                            Sensitivity
                                </div>
                          <input
                            type="number"
                            className="input-number"
                            value={radarSensitivity}
                            onChange={(e) => handleSensitivityChange(parseInt(e.target.value))}
                          />
                        </div>
                        <div className="settings-cell">
                          <div className="settings-title">Display On Timer</div>
                          <input
                            type="number"
                            className="input-number"
                            value={radarHold}
                            onChange={(e) => handleHoldChange(parseInt(e.target.value))}
                          />
                          <span className="speed-unit"> sec</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='settings-box'>
                <div className="settings-row">
                  <div className="settings-cell"> 
                  <button className="btn-operations" onClick={handleRefreshDevices}>
                    <div className="btn-container">
                      Refresh Devices
                      {loadingDeviceOnline && <div className="loader"></div>}
                    </div>
                  </button>
                  <button className="btn-operations" onClick={handleGetSettings}>
                    <div className="btn-container">
                      Retrieve Device Settings
                      {loadingDeviceSettings && <div className="loader"></div>}
                    </div>
                  </button>
                  <button className="btn-operations" onClick={handleSavePreset}>
                    Save Preset
                  </button>
                  <button className="btn-operations" onClick={handlePushUpdate}>
                    Save Preset & Push Update
                  </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="scrolling-section">
              <div className="images-box">
                  <div className="library-container">Image Reference Library</div>
                    <div className="image-grid">
                        {imageList.map((image, index) => (
                            <div key={index} className="image-container">
                                <span className="index">{index + 1}</span>
                                <img src={image} alt={`Image ${index + 1}`} />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;