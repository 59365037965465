import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import TopBar from './TopBar';
import './Settings.css';
import onlineIcon from '../gallery/online.png';
import offlineIcon from '../gallery/offline.png';

import { parse, isWithinInterval } from 'date-fns';

ChartJS.register(
  ...registerables,
  annotationPlugin
);

function Settings() {
  const url = 'https://lespeedsigns-server.australiaeast.cloudapp.azure.com:3001';
  //const url = 'http://localhost:3001';

  const navigate = useNavigate();

  const checkValidUser = () => {
    if (sessionStorage.getItem('username') !== 'sign_blast') {
      navigate('/');
      sessionStorage.removeItem('username');
      sessionStorage.removeItem('company');
    }
  };

  const [collapsedChart, setCollapsedChart] = useState(false);
  const [collapsedStatus, setCollapsedStatus] = useState(false);
  const [view, setView] = useState('hour');
  const [logView, setLogView] = useState('last_24hr');
  const [deviceStatuses, setDeviceStatuses] = useState({});

  useEffect(() => {
    checkValidUser();
    fetchDevicesData();
  }, []);
  
  useEffect(() => {
    if (Object.keys(deviceStatuses).length > 0) {
      fetchLogsData(logView);
    }
  }, [deviceStatuses, logView]);

  const [lineData, setLineData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Total Devices',
        data: [],
        fill: true,
        backgroundColor: 'rgba(54, 162, 235, 0)',
        borderColor: '#36A2EB',
        borderWidth: 2,
        pointRadius: 5,
        pointHoverRadius: 7,
      },
      {
        label: 'Offline (Sum)',
        data: [],
        fill: true,
        backgroundColor: 'rgba(255, 0, 0, 0.5)',
        borderColor: '#FF6384',
        borderWidth: 2,
        pointRadius: 5,
        pointHoverRadius: 7,
      },
    ],
  });
  const [logData, setLogData] = useState([]);

  useEffect(() => {
    updateChartData(view);
  }, [view, logData]);

  const fetchDevicesData = async () => {
    try {
      const response = await fetch(url + `/admin/latest_status`);
      if (!response.ok) {
        throw new Error('Failed to fetch device settings');
      }

      const data = await response.json();
      const formattedDeviceStatuses = data.devices.reduce((acc, device) => {
        acc[device.label] = {
          online: device.connected === 1,
          label: device.label,
          sn: device.id,
          owner: device.username,
          note: device.note,
          address: device.addr,
        };
        return acc;
      }, {});

      setDeviceStatuses(formattedDeviceStatuses);
    } catch (error) {
      console.error('Error fetching device settings:', error);
    }
  };

  const fetchLogsData = async (view) => {
  try {
    const response = await fetch(`${url}/admin/logs_${view}`);
    if (!response.ok) {
      throw new Error('Failed to fetch logs data');
    }

    const data = await response.json();
    const logs = data.logs || [];

    // Prepare the logs data
    const parsedLogs = logs.map(log => {
      const onlineDevices = log.online_devices ? log.online_devices.split(',') : [];
      const allDevices = Object.keys(deviceStatuses);

      // Create a map of device statuses
      const deviceStatusMap = allDevices.reduce((acc, device) => {
        acc[device] = onlineDevices.includes(device);
        return acc;
      }, {});

      return {
        timestamp: new Date(log.created_at).toLocaleString(),
        ...deviceStatusMap,
      };
    });

    // Sort logs in descending order by timestamp
    parsedLogs.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));

    setLogData(parsedLogs);
  } catch (error) {
    console.error('Error fetching logs data:', error);
  }
};

  const handleLogViewChange = (newView) => {
    setLogView(newView);
    fetchLogsData(newView);

    if (newView === 'last_24hr') {
      handleViewChange('hour');
    } else if (newView === 'last_7days') {
      handleViewChange('day');
    } else {
      handleViewChange('month');
    }
  };

  useEffect(() => {
    updateChartData(view);
  }, [view]);

  const updateChartData = (view) => {
    const now = new Date();
    let labels = [];
    let onlineData = [];
    let offlineData = [];

    // Initialize tracking for each period based on view
    if (view === 'hour') {
        for (let i = 23; i >= 0; i--) {
            const hour = new Date(now.getFullYear(), now.getMonth(), now.getDate(), now.getHours() - i);
            labels.push(hour.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
            onlineData.push(0);
            offlineData.push(0);
        }
    } else if (view === 'day') {
        // Last 7 days
        for (let i = 6; i >= 0; i--) {
            const day = new Date(now.getFullYear(), now.getMonth(), now.getDate() - i);
            labels.push(day.toLocaleDateString([], { day: '2-digit', month: 'short' }));
            onlineData.push(0);
            offlineData.push(0);
        }
    } else if (view === 'month') {
        // Last 30 days
        for (let i = 29; i >= 0; i--) {
          const day = new Date(now.getFullYear(), now.getMonth(), now.getDate() - i);
          labels.push(day.toLocaleDateString([], { day: '2-digit', month: 'short' }));
          onlineData.push(0);
          offlineData.push(0);
        }
    }

    // Object to track total and count for averaging
    const periodData = {};

    // Process log data
    logData.forEach(log => {
        const logTime = new Date(log.timestamp);
        let periodStr = '';

        if (view === 'hour') {
            const logHourStr = new Date(logTime.setMinutes(0, 0, 0)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            periodStr = logHourStr;
        } else if (view === 'day') {
            const logDayStr = new Date(logTime.setHours(0, 0, 0, 0)).toLocaleDateString([], { day: '2-digit', month: 'short' });
            periodStr = logDayStr;
        } else if (view === 'month') {
            const logDayStr = new Date(logTime.setHours(0, 0, 0, 0)).toLocaleDateString([], { day: '2-digit', month: 'short' });
            periodStr = logDayStr;
        }

        const totalDevices = Object.keys(deviceStatuses).length + 1;
        const onlineDevices = Object.values(log).filter(status => status).length;
        const offlineDevices = totalDevices - onlineDevices;

        // Initialize or update period data
        if (!periodData[periodStr]) {
            periodData[periodStr] = { totalOnline: 0, totalOffline: 0, count: 0 };
        }

        periodData[periodStr].totalOnline += totalDevices;
        periodData[periodStr].totalOffline += offlineDevices;
        periodData[periodStr].count += 1;
    });

    // Update data arrays
    labels.forEach((label, index) => {
        const data = periodData[label];
        if (data) {
            onlineData[index] = Math.ceil(data.totalOnline / data.count); // Average of total devices, rounded up
            offlineData[index] = Math.ceil(data.totalOffline / data.count); // Average of offline devices, rounded up
        } else {
            onlineData[index] = 0;
            offlineData[index] = 0;
        }
    });

    setLineData({
        labels,
        datasets: [
            {
                label: 'Total Devices',
                data: onlineData,
                fill: true,
                backgroundColor: 'rgba(54, 162, 235, 0.1)',
                borderColor: '#36A2EB',
                borderWidth: 2,
                pointRadius: 5,
                pointHoverRadius: 7,
            },
            {
                label: 'Offline (Avg.)',
                data: offlineData,
                fill: true,
                backgroundColor: 'rgba(255, 0, 0, 0.2)',
                borderColor: '#FF0000',
                borderWidth: 2,
                pointRadius: 5,
                pointHoverRadius: 7,
            },
        ],
    });
  };

  const handleViewChange = (newView) => {
    setView(newView);
  };

  const options = {
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const dataset = tooltipItem.dataset;
            const value = dataset.data[tooltipItem.dataIndex];
            return `${dataset.label}: ${value}`;
          },
        },
      },
      annotation: {
        annotations: {
          line: {
            type: 'line',
            borderColor: 'rgba(0,0,0,0.2)',
            borderWidth: 1,
            mode: 'vertical',
            value: 0,
            scaleID: 'x',
            borderDash: [2, 2],
          },
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 20,
        right: 80,
        top: 20,
        bottom: 20
      }
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          maxRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
      },
    },
    interaction: {
      intersect: false,
      mode: 'index',
    },
  };

  window.handleDownload = async () => {
    const startDate = localStorage.getItem('startDate');
    const endDate = localStorage.getItem('endDate');

    if (startDate && endDate) {
        const start = new Date(startDate);
        const end = new Date(endDate);

        if (start < end) {
            try {
                // Fetch all logs from the server
                const response = await fetch('https://lespeedsigns-server.australiaeast.cloudapp.azure.com:3001/admin/logs_all');
                if (!response.ok) {
                    throw new Error('Failed to fetch all logs');
                }

                const data = await response.json();
                const logs = data.logs || [];

                // Define the maximum number of devices
                const getMaxDevices = (logs) => {
                    return Math.max(...logs.map(log => log.total_devices));
                };

                // Prepare CSV data
                const prepareCSVData = (logs) => {
                    const maxDevices = getMaxDevices(logs);
                    const deviceLabels = Array.from({ length: maxDevices }, (_, i) => `K${(i + 1).toString().padStart(2, '0')}`);

                    const csvRows = [];
                    csvRows.push(['Timestamp', ...deviceLabels].join(','));

                    logs.forEach(log => {
                        const onlineDevices = log.online_devices.split(',');
                        const row = [
                            `"${convertToBrisbaneTime(log.created_at)}"`, // Enclose timestamp in quotes
                            ...deviceLabels.map(device => onlineDevices.includes(device) ? 'On' : 'Off')
                        ].join(',');
                        csvRows.push(row);
                    });

                    return csvRows.join('\n');
                };

                // Convert timestamp to Brisbane time
                const convertToBrisbaneTime = (isoString) => {
                    const date = new Date(isoString);
                    const options = {
                        timeZone: 'Australia/Brisbane',
                        year: 'numeric',
                        month: '2-digit',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                        hour12: false
                    };
                    return new Intl.DateTimeFormat('en-AU', options).format(date);
                };

                // Filter logs
                const filteredLogs = logs.filter(log => {
                    try {
                        const logDate = new Date(log.created_at);
                        return logDate >= start && logDate <= end;
                    } catch (error) {
                        console.error('Error parsing date:', error);
                        return false;
                    }
                });

                // Convert filtered logs to CSV format
                const csvData = prepareCSVData(filteredLogs);

                // Create CSV filename
                const formatDate = (date) => {
                    const d = new Date(date);
                    return `${d.getFullYear()}-${(d.getMonth() + 1).toString().padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
                };

                const csvFilename = `Logs-${formatDate(startDate)}-${formatDate(endDate)}.csv`;

                // Trigger CSV download
                const downloadCSV = (csvData, filename) => {
                    const csvFile = new Blob([csvData], { type: 'text/csv' });
                    const downloadLink = document.createElement('a');
                    downloadLink.download = filename;
                    downloadLink.href = URL.createObjectURL(csvFile);
                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                };

                downloadCSV(csvData, csvFilename);

            } catch (error) {
                console.error('Error fetching or processing logs:', error);
            }

            // Optionally clear localStorage if needed
            localStorage.removeItem('startDate');
            localStorage.removeItem('endDate');
        } else {
            alert("Error: end date is before the start date.");
        }
    } else {
        alert("Error: please enter both dates.");
    }
  };

  const openDownloadModal = () => {
    const popupWindow = window.open('', 'DownloadLogs', 'width=600,height=400');
    if (popupWindow) {
        popupWindow.document.write(`
            <html>
                <head><title>Download Logs</title></head>
                <body>
                    <h2>Download Logs</h2>
                    <p>Start Date: <input type="datetime-local" id="startDate" /></p>
                    <p>End Date: <input type="datetime-local" id="endDate" /></p>
                    <button id="downloadButton" background-color="Blue">Download as CSV</button>
                    <script>
                        document.getElementById('downloadButton').onclick = function() {
                            // Store the dates in localStorage
                            localStorage.setItem('startDate', document.getElementById('startDate').value);
                            localStorage.setItem('endDate', document.getElementById('endDate').value);
                            // Call the handleDownload function in the main window
                            window.opener.handleDownload();
                        };
                    </script>
                </body>
            </html>
        `);
        popupWindow.document.close();
    }
  };




  return (
    <div className="settings">
      <TopBar />
      <div className="main-content">
        <div className={`charts-container ${collapsedChart ? 'collapsed' : ''}`}>
          {!collapsedChart && (
            <div className="line-chart">
              <div className="selector">
                <button className={`view-button ${view === 'month' ? 'active' : ''}`} onClick={() => handleLogViewChange('last_30days')}>Month</button>
                <button className={`view-button ${view === 'day' ? 'active' : ''}`} onClick={() => handleLogViewChange('last_7days')}>Week</button>
                <button className={`view-button ${view === 'hour' ? 'active' : ''}`} onClick={() => handleLogViewChange('last_24hr')}>Day</button>
              </div>
              <Line data={lineData} options={options} />
            </div>
          )}
          <div className="collapse-arrow" onClick={() => setCollapsedChart(!collapsedChart)}>
            <div className={`arrow ${collapsedChart ? 'expand' : 'collapse'}`}></div>
          </div>
          {collapsedChart && <div className="collapsed-text"><b>Chart</b></div>}
        </div>
        <h4>Latest Status</h4>
        <div className={`devices-container ${collapsedStatus ? 'collapsed' : ''}`}>
          {!collapsedStatus && (
            <div className="devices-container">
              {Object.keys(deviceStatuses).map(key => (
                <div
                  key={key}
                  className={`device-icon ${deviceStatuses[key].online ? 'online' : 'offline'}`}
                >
                  <span><b>{key}</b></span>
                  <span>{deviceStatuses[key].sn}</span>
                  <div className={`status-line ${deviceStatuses[key].online ? 'online' : 'offline'}`}></div>
                  <div className="tooltip">
                    <div>Owner: {deviceStatuses[key].owner}</div>
                    <div>Note: {deviceStatuses[key].note}</div>
                    <div>Addr: {deviceStatuses[key].address}</div>
                  </div>
                </div>
              ))}
            </div>
          )}
          <div className="collapse-arrow" onClick={() => setCollapsedStatus(!collapsedStatus)}>
            <div className={`arrow ${collapsedStatus ? 'expand' : 'collapse'}`}></div>
          </div>
          {collapsedStatus && <div className="collapsed-text"><b>Status</b></div>}
        </div>
        <h4>Devices Logs</h4>
        <div className="log-selector">
          <button className={`view-button ${logView === 'last_24hr' ? 'active' : ''}`} onClick={() => handleLogViewChange('last_24hr')}>Last 24 Hours</button>
          <button className={`view-button ${logView === 'last_7days' ? 'active' : ''}`} onClick={() => handleLogViewChange('last_7days')}>Last 7 Days</button>
          <button className={`view-button ${logView === 'last_30days' ? 'active' : ''}`} onClick={() => handleLogViewChange('last_30days')}>Last 30 Days</button>
          <button className="download-button" onClick={openDownloadModal}>Download</button>
        </div>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Time Stamp</th>
                {Object.keys(deviceStatuses).map(key => (
                  <th key={key}>{key}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {logData.map((log, index) => (
                <tr key={index}>
                  <td>{log.timestamp}</td>
                  {Object.keys(deviceStatuses).map(device => (
                    <td key={device}>
                      <img src={log[device] ? onlineIcon : offlineIcon} className="status-icon" alt={log[device] ? 'Online' : 'Offline'} />
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default Settings;