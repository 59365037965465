import React, { useState } from 'react';
import './Quote.css';
import NavBar from './NavBar';
import Footer from './Footer';

function Quote() {
    const [formData, setFormData] = useState({
        name: '',
        company: '',
        email: '',
        phone: '',
        details: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        fetch('https://lespeedsigns-server.australiaeast.cloudapp.azure.com:3001/send-quote', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
        .then(response => response.json())
        .then(data => {
            alert(data.message);
        })
        .catch(error => {
            console.error('Error:', error);
        });
    };

    return (
        <div className="quote-container">
            <NavBar />
            <div className="placeholder"></div>
            <div className="quote-main">
                <div className="quote-tab">
                    <h2>Request A Quote</h2>
                    <p>Simply fill out the form below to request a quote! Or, give us a call now on: <a href="tel:+61-000-000-000">(07) 0000 0000</a></p>
                </div>
                <form className="quote-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Name *</label>
                        <input type="text" name="name" value={formData.name} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Company</label>
                        <input type="text" name="company" value={formData.company} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Email *</label>
                        <input type="email" name="email" value={formData.email} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Phone</label>
                        <input type="text" name="phone" value={formData.phone} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Details</label>
                        <textarea name="details" value={formData.details} onChange={handleChange}></textarea>
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <button type="submit">Submit</button>
                    </div>
                </form>
            </div>
            <Footer />
        </div>
    );
}

export default Quote;
